// テキストフォーム フォーカス時の挙動

// フォーカスイン
$(document).on(
  "focusin",
  ".check-input .is-require",
  function() {
    $(this).removeClass("is-default");
    $(this).closest(moduleTextform).removeClass("is-default");
  }
);

// フォーカスアウト
$(document).on(
  "focusout",
  ".check-input .is-require",
  function() {
    if ($(this).val() === ""){
      $(this).addClass("is-default");
      $(this).closest(moduleTextform).addClass("is-default");
    }
  }
);