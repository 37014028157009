// slick
$(function(){
    function slickSetting(){
        var width = $(window).width();
        if(width >= 768){
            $('.js-slick-main').not('.slick-initialized').slick({
                infinite: false,
                centerMode: false,
                arrows: true,
                dots: false,
                variableWidth: true,
                arrows: true,
                dots: false,
            });
            $('.js-slick-sub').not('.slick-initialized').slick({
                infinite: false,
                dots: false,
                variableWidth: true,
                centerMode: false,
                arrows: true,
                slidesToShow: 4,
                slidesToScroll: 4,
                responsive: [{
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },{
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                }],
            });
            $('.js-slick-ticket').not('.slick-initialized').slick({
                infinite: false,
                dots: false,
                variableWidth: true,
                centerMode: false,
                arrows: true,
                slidesToShow: 3,
                slidesToScroll: 3,
                responsive: [{
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },{
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }],
            });
        } else {
            $('.js-slick-main.slick-initialized,.js-slick-sub.slick-initialized,.js-slick-ticket.slick-initialized').slick('unslick');
        }
    }
    $(window).on('resize orientationChange', function(){
        slickSetting();
    });
    slickSetting();
});

// ドロワーメニュー
$('.button-toggle').on('click',function(){
    if($(this).hasClass('active')){
        $(this).removeClass('active');
        $('.header-container').removeClass('open');
        $('.drawer').removeClass('open');
        $('.overlay').removeClass('open');
        $('body').removeClass('overlay-visible');
    } else {
        $(this).addClass('active');
        $('.header-container').addClass('open');
        $('.drawer').addClass('open');
        $('.overlay').addClass('open');
        $('body').addClass('overlay-visible');
    }
});
$('.overlay, .button-close').on('click',function(){
    $('.button-toggle').removeClass('active');
    $('.header-container').removeClass('open');
    $('.overlay').removeClass('open');
    $('.drawer').removeClass('open');
    $('body').removeClass('overlay-visible');
});

// パスワードトグル
$(".toggle-password").click(function () {
    let input = $(this).prev("input");
    if (input.attr("type") == "password") {
        input.attr("type", "text");
    } else {
        input.attr("type", "password");
    }
});

// remodal.js
$(document).on('opening', '.remodal', function () {
    $('body').addClass('overlay-visible');
});

$(document).on('closed', '.remodal', function () {
    $('body').removeClass('overlay-visible');
});

// sticky-kit.js
$(function(){
    $('[data-sticky_column]').stick_in_parent({
        parent: '[data-sticky_parent]',
        offset_top: 68
    })
});

// sticky-kit.js （マップ画面）
$(function(){
    $('.page_map .box-search-wrap').stick_in_parent({
        parent: 'body',
    }).on("sticky_kit:stick", function(e) {
        $('.page_map .box-search-wrap').addClass('fixed');
    }).on("sticky_kit:unstick", function(e) {
        $('.page_map .box-search-wrap').removeClass('fixed');
    });
});

// マップボタン押下時スクロール （マップ画面）
$(function(){
    $(window).on('load scroll resize',function(){
        let nowPosition = $(window).scrollTop();
        let trigger = $(window).height();
        let targetPosition = $('.sidebar').scrollTop();
        let targetHeight = $('.button-group-bottom-sub').outerHeight();
        let bp = 768;
        let windowW = $(window).width();
        if(windowW < bp){
            $('.button-group-bottom-sub').css('top',- targetHeight + 'px');
            // $('.scroll-visibility').clone().addClass('cloned').insertAfter('.sidebar');
            if(nowPosition >= trigger){
                if(!$('.button-group-bottom-main').hasClass('active')){
                    $('.button-group-bottom-main').addClass('active').fadeIn();
                }
            }else{
                if($('.button-group-bottom-main').hasClass('active')){
                    $('.button-group-bottom-main').removeClass('active').fadeOut();
                }
            }
        }
    })
    $('.button-map').click(function () {
        $('body, html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });
});

// マップ画面 サイドバーの高さ位置指定
$(window).on('load resize',function(){
    let windowW = $(window).width();
    let windowH = $(window).height();
    let headerH = $('.header').outerHeight();
    let boxSearchWrapH = $('.box-search-wrap').outerHeight();
    let sidebarVsibleArea = 89;
    let bp = 768;
    if(windowW > bp){
        $('.sidebar').css('height',windowH - headerH - boxSearchWrapH - 24 + 'px');
        $('.sidebar-wrap').css('top',headerH + boxSearchWrapH + 8 + 'px');
    } else {
        $('.sidebar').css('height','auto');
        $('.sidebar-wrap').css('top',windowH - sidebarVsibleArea + 'px');
    }
});

// sticky-kit.js（デバイスによって有効化）
// $(function(){
//     //480pxがブレークポイントの場合
//     var bp = 480;
    
//     //Stickyを有効にする
//     var sticky_enable = function(){
//       $('[data-sticky_column]').stick_in_parent({
//         parent: '[data-sticky_parent]'
//       })
//     }
//     //Stickyを無効にする
//     var sticky_disable = function(){
//         $('[data-sticky_column]').trigger('sticky_kit:detach');
//     }
//     //ウインドウサイズによって有効・無効の関数を実行
//     $(window).on('load resize',function(){
//         var w = $(window).width();
//         if(w > bp){
//             sticky_disable();
//         } else {
//             sticky_enable();
//         }
//     });
// });

// テキストさらに表示ボタン
$(function() {
    const elements = document.querySelectorAll('.js-more-text');

    Array.from(elements).forEach(function(el){
        //ボタンを取得
        const btn = el.querySelector('.more-button');
        //コンテンツを取得
        const content = el.querySelector('.more-text');
        //ボタンクリックでイベント発火
        btn.addEventListener('click', function(){
            if(!content.classList.contains('open')){
                //コンテンツの実際の高さを代入
                //キーワード値（none、max-content等）では動作しないので注意
                content.style.maxHeight = content.scrollHeight + 'px';
                //openクラスを追加
                content.classList.add('open');
                //もっと見るボタンのテキストを設定
                btn.textContent = '閉じる';
            } else {
                //コンテンツの高さを固定値を代入
                content.style.maxHeight = '150px';
                //openクラスを削除
                content.classList.remove('open');
                //もっと見るボタンのテキストを設定
                btn.textContent = 'さらに表示';
            }
        });
    });
});

// 埋め込み動画サムネイルカスタマイズ
$(function() {
  //Mobile判定
  var mobile = false;
  var ua = navigator.userAgent;
  if (
    ua.indexOf("iPhone") > 0 ||
    ua.indexOf("iPod") > 0 ||
    ua.indexOf("iPad") > 0 ||
    (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
  ) {
    mobile = true;
  }
  //スマホの場合muted属性追加
  if (mobile) {
    $("iframe").each(function() {
      $(this).attr("muted", "");
    });
  }
  $(".thumb,.button-play").click(function() {
    var player = $(this).next("iframe")[0].contentWindow;
    player.postMessage('{"event":"command","func":"playVideo","args":""}', "*");
    $(this).hide();
  });
});

// タブ切り替え
$(function () {
    $('.tab-menu .tab').on('click', function () {
        $('.panel').removeClass('active');
        $(this).addClass('active').siblings('.tab').removeClass('active');
        $(this).closest('.tab-menu').next('.tab-contents').find('.panel').removeClass('active');
        const index = $(this).index();
        $('.panel').hide().eq(index).fadeIn();
    });
});

// アコーディオン
$(function(){
    $('.js-accordion .acc-header').click(function(){
        $(this).toggleClass('active');
        $(this).next('.acc-content').slideToggle('fast');
    });
});

// レスポンシブ アコーディオン（スマホ時のみ）
$(function(){
    $('.js-resp-accordion .acc-header').click(function(){
        $(this).toggleClass('active');
        $(this).next('.acc-content').slideToggle('fast');
    });
    function accRespSetting(){
        var width = $(window).width();
        if(width < 768){
            $('.js-accordion .acc-header').click(function(){
                $(this).toggleClass('active');
                $(this).next('.acc-content').slideToggle('fast');
            });
        }
    }
    $(window).on('resize orientationChange', function(){
        accRespSetting();
    });
    accRespSetting();
});

$(function(){
    $('.checkbox-filter').click(function() {
        if ($(this).prop("checked")) {
            $(this).parent(".label-group").addClass('active');
        } else {
            $(this).parent(".label-group").removeClass('active');
        }
    });
});

$(function(){
    $(".radio-filter").change(function(){
        $("input[type='radio'][name='"+ $(this).attr("name")+ "']").each(function(){
            if($(this).is(":checked")){
                $(this).parent(".label-group").addClass("active");
            }else{
                $(this).parent(".label-group").removeClass("active");
            }
        });
    });
});

// datepicker
$('.input-val-date').datepicker({
    dateFormat: "yy/mm/dd",
});
